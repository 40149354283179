import React, { useEffect, useRef, useState } from "react";
import Checkbox from "../../Checkbox/Checkbox";
import Select from "../../Select/select";
import Button from "../../Buttons/button";
import TextField from "../../TextField/TextField";
import {getInitializeServicesData, getServiceTemplate, updateCustomerServiceUsegesReport, selectCompanyDashboard, getSetupServicesData } from '../../../redux/slices/companyDashboardSlice';
import { useDispatch, useSelector } from "react-redux";
import errorIcon from '../../../assets/icons/error-icon.svg'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { current } from "@reduxjs/toolkit";
import uploadIcon from "./../../../assets/images/ic_download.png";
import DragDrop from "../../DragDrop/DragDrop";
import MonthPicker from "../../MonthPicker/MonthPicker";
import calendarIcon from "../../../assets/images/circum_calendar.png";
import chevronIcon from "../../../assets/images/chevronIcon.png";
import { addMonths, subMonths } from "date-fns";

function UpdateUserServiceDetails({id, setPopup}){
  const dispatch = useDispatch();
  const State  = useSelector(selectCompanyDashboard);
  const {CategoriesAndUsersData, SetServicesData, template} = State
  const setupServices = SetServicesData?.setup_services;
  const [selected, setSelected] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [file, setFile] = useState(""); 
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(()=>{
    dispatch(getServiceTemplate(id));
    dispatch(getSetupServicesData(id));
  },[])

  useEffect(()=>{
    setFile(setupServices?.useges_file_name)
    setSelectedDate(setupServices?.billing_date || new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()))
  },[id, setupServices])

  const fileTypes = ["JPG", "PNG", "GIF", "PDF","CSV", "XLSX"];
  const element = document.getElementById('calendarWrap');

  useEffect(() => {
    updateSelectedDate(selectedDate);
  }, [selectedDate]);

  const updateSelectedDate = (date) => {
    const currentDate = date ? new Date(date) : new Date();
    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
    });
    const formattedDate = formatter.format(currentDate);

    const [month, year] = formattedDate.split(" ");
    const formattedDateString = `${month}, ${year}`;

    const calendarVal = document.getElementById("calendarVal");
    if (calendarVal) {
      calendarVal.textContent = formattedDateString;
    }
  };

  const incrementMonth = (prevDate) => {
    setSelectedDate((prevDate) => {
      const currentDate = prevDate ? prevDate : new Date();
      return addMonths(currentDate, 1);
    });
  };

  const decrementMonth = (prevDate) => {
    setSelectedDate((prevDate) => {
      const currentDate = prevDate ? prevDate : new Date();
      return subMonths(currentDate, 1);
    });
  };

  const handleUsegeDataSave = () => {
    const formData = new FormData();
    formData.append("billing_period", selectedDate);
    formData.append("file", file);
    dispatch(updateCustomerServiceUsegesReport(id, formData));
    setPopup(false);
  };

  const fileDownload = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(urlBlob); // Clean up after download
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  return (
  <div className="transform rounded-[24px] bg-[#363D50] transition-all flex">
    <div className="flex ">
      <div className="flex flex-col md:w-[600px] pt-[24px] pr-[24px] pb-[24px] pl-[24px] gap-[16px] SetupServiceModel text-left">
        <div className="flex h-[40px] justify-between pt-[30px] pb-[45px] border-b border-color items-center">
          <div className="flex flex-start flex-col text-left">
            <div className="text-primary !text-[24px] !leading-[36px]">Upload user service data</div>
          </div>
          <svg
            className="cursor-pointer"
            onClick={() => {
              setPopup(false);
            }}
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="flex flex-col gap-[24px] pb-[16px] addServiceForm">
          <div className="flex flex-start flex-col text-left">
            <div className="flex flex-start step_heading">Upload new user service data. Thryft will generate any new reports using the latest data provided.</div>
          </div>

          <div className="flex flex-col MonthPickerWrap onlyMonth">
            <label className="flex flex-start justify-between text-primary !font-semibold mb-[8px]">
              Select data month
              {/* { */}
              {/*   !formValid && */}
              {/*   ( */}
              {/*     <span> */}
              {/*       <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" /> */}
              {/*     </span> */}
              {/*   ) */}
              {/* } */}
            </label>
            <div className="calendarWrap" id="calendarWrap" >
              <img src={calendarIcon} alt="icon" className="calendarIcon" />
              {selectedDate && (
                <MonthPicker
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  updateSelectedDate={updateSelectedDate}
                />
              )}
              <div id="calendarVal" className="calendarVal"></div>
              <div className="calChev">
                <div className="upBtn" onClick={() => {incrementMonth(selectedDate)}}>
                  <img src={chevronIcon} alt="icon"  />
                </div>
                <div className="downBtn" onClick={() => {decrementMonth(selectedDate)}}>
                  <img src={chevronIcon} alt="icon"  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DragDrop setFile={setFile}/>
        <div className=" flex justify-between items-center md:h-[53px] gap-[12px] popupFooter">
          <div className="downloadLink cursor-pointer">
            <img src={uploadIcon} alt="icon" className="iconDown" />
            {setupServices?.useges_file_name ? (
              <span
                onClick={() => fileDownload(setupServices.useges_file_url, setupServices.useges_file_name)}
              >
                {setupServices.useges_file_name}
              </span>
            ) : (
              <a href={template.filePath}>
                  <span className="text-secondary downloadLink ">
                    {template.download_label}
                  </span>
              </a>
            )}
          </div>
          <div className=" flex justify-end items-center md:h-[53px] gap-[12px]">
            <Button
              onClick={() => setPopup(false)}
              className="btnHover bg-gray-700 btnText"
              outlined
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleUsegeDataSave()}
              className="btnHover !pr-7 !pl-7"
              fill
            >
              Save
            </Button>
          </div>

      </div>
      </div>
    </div>
  </div>
  );
}

export default UpdateUserServiceDetails;